import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Button } from '../Components/Button/Button';
import { Footer } from '../Components/HeaderFooter/Footer';
import { Header } from '../Components/HeaderFooter/Header';
import { useGetDoc } from '../Hooks/UseGetDoc';
import { Form } from '../Components/Form/Form'


import style from './Singlepage.module.css'
import { Content } from '../Components/Layout/Content';
import { ExampleWork } from '../Components/Blocks/ExampleWork';
import { FormCall } from '../Components/Form/FormCall';
import { Modal } from '../Components/Modal/Modal';
import { Tokar } from './Technology/Tokar';
import { Plazma } from './Technology/Plazma';
import { Electro } from './Technology/Electro';
import { ShlifKrug } from './Technology/ShlifKrug';
import { ShlifPlos } from './Technology/ShlifPlos';
import { Frezer } from './Technology/Frezer';
import { Gidro } from './Technology/Gidro';
import { HowWeWork } from '../Components/Blocks/HowWeWork';
import { Collaboration } from '../Components/Blocks/Collaboration';
import { Breadcrumbs } from '../Components/Breadcrumbs/Breadcrumbs';

export const Singlepage = () => {

    useEffect(() => {
        document.body.style.backgroundColor = 'var(--second)';
    }, []);

    const { docs } = useGetDoc('services')

    const { link } = useParams()
    const service = docs.find((service) => service?.link === link)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const form = useRef()
    const buttonHandler = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <Header
                service={service}
            />

            {submitted
                ? <Modal
                    submitted={submitted}
                    loading={loading}>
                </Modal>
                : null
            }

            <main className={style.main}>
                <Content backgroundColor={"var(--second)"}>
                    <div className={style.about}>
                        <div className={style.text}>
                            <h1 className={style.title}>{service?.name}</h1>
                            <p className={style.description}>{service?.description}</p>
                            <Button
                                buttonStyle={"btn--white"}
                                buttonSize={"btn--l"}
                                // IconRight={'phone'}
                                // IconColor={'var(--second)'}
                                onClick={() => buttonHandler(form)}
                            >Получить расчет стоимости
                            </Button>
                        </div>
                        <div className={style.imageRound}>
                            <div className={style.image}>
                                <img src={service?.cover} />
                            </div>
                        </div>
                    </div>
                </Content>

                <div className={style.imageBox}>
                    <img src={service?.cover} />
                </div>


                <Content>
                    <div className={style.content}>
                        <div className={style.textContent}>

                            {service?.link === 'tokarnyye-raboty'
                                ? <Tokar buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'plazmennaya-rezka'
                                ? <Plazma buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'elektroerozionnaya-rezka'
                                ? <Electro buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'kruglaya-shlifovka'
                                ? <ShlifKrug buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'ploskaya-shlifovka'
                                ? <ShlifPlos buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'frezernyye-raboty'
                                ? <Frezer buttonHandler={buttonHandler} form={form} />
                                : null
                            }

                            {service?.link === 'gidroabrazivnaya-rezka'
                                ? <Gidro buttonHandler={buttonHandler} form={form} />
                                : null
                            }
                        </div>
                        <div className={style.callform}>
                            <FormCall setSubmitted={setSubmitted} setLoading={setLoading} />
                        </div>
                    </div>
                </Content>

                <ExampleWork />
                <HowWeWork />
                <Collaboration />

                <Content>
                    <div className={style.form} ref={form}>
                        <Form center={true} />
                    </div>
                </Content>

            </main>

            <Footer />
        </>);
};
