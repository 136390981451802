import React from 'react';
import style from './FormUnit.module.css'
import { useState } from 'react';

export const Checkbox = ({ id, ...inputProps }) => {

    const [personData, setPersonData] = useState(false)

    return (
        <div className={style.checkbox}>
            <label htmlFor={id} className={style.checkLabel}>
                <input className={style.hiddenCheckbox}
                    {...inputProps}
                    id={id}
                    type='checkbox'
                    onChange={(e) => setPersonData(!e.target.checked)}
                    required
                />
                <span className={style.newCheckbox} ></span>
                Согласен на обработку данных
            </label>
            {personData &&
                <span className={style.errorMessagePersonData}>
                    Нужно ваше согласие
                </span>

            }

        </div >
    );
};