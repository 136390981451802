import { initializeApp } from "firebase/app";

import { getAuth} from 'firebase/auth'
import { getStorage } from "@firebase/storage";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBOhf-llIZt1qWSpWM6ILM-YmyvoJDMqv8",
  authDomain: "promstroiservice-1d2a7.firebaseapp.com",
  projectId: "promstroiservice-1d2a7",
  storageBucket: "promstroiservice-1d2a7.appspot.com",
  messagingSenderId: "621600885263",
  appId: "1:621600885263:web:bd04085642624a24f0717a"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const storage = getStorage (app)
export const db = getFirestore (app)