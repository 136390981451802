import React, { useEffect, useState } from 'react';
import { useGetDoc } from '../Hooks/UseGetDoc';
import style from './getData.module.css'
import FirebaseData from '../Firebase/firebase-data';



export const GetCall = () => {

    const [services, setServices] = useState([])

    const getServices = async () => {
        const data = await FirebaseData.getAllServices()
        setServices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    useEffect(() => {
        getServices()
    }, []);

    const handlerDeleteProduct = async (id) => {
        await FirebaseData.deleteService(id)
        getServices()
    }

    const callOrder = services.filter(unit => unit.typeForm === 'callFeedback')

    return (
        <div>

            <h2 className={style.title}>Перезвонить</h2>

            <table className={style.table}>
                <tr className={style.row}>
                    <th>Дата</th>
                    <th>Имя</th>
                    <th>Телефон</th>
                    <th>Город</th>
                </tr>

                {callOrder.map((unit) => (
                    <tr key={unit.id} >

                        <td style={{ textAlign: 'center' }}>{unit.date}</td>
                        <td style={{ textAlign: 'center' }}>{unit.name}</td>
                        <td style={{ textAlign: 'center' }}>{unit.phone}</td>
                        <td style={{ textAlign: 'center' }}>{unit.city}</td>
                        <td><p className={style.delete} onClick={(e) => handlerDeleteProduct(unit.id)}>Удалить</p></td>
                    </tr>

                ))}
            </table>
        </div>
    );
};
