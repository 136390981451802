import React from 'react';
import style from './FormUnit.module.css'
import { useState } from 'react'


export const Input = ({
    label,
    placeholder,
    errorMessage,
    onChange,
    id,
    inputmode,
    checked, ...inputProps }) => {

    const [focused, setFocused] = useState(false)
    const handleFocus = (e) => {
        setFocused(true)
    }


    return (
        <div className={style.input}>
            <input
                {...inputProps}
                id={id}
                onChange={onChange}
                inputMode={inputmode}
                onBlur={handleFocus}
                focused={focused.toString()}
                placeholder={placeholder}

            />
            <label htmlFor={id}>{label}</label>
            {errorMessage
                ? <span className={style.errorMessage}>{errorMessage}</span>
                : null
            }
        </div>
    );
};