import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const ShlifPlos = ({ buttonHandler, form }) => {
    return (
        <>
            <h2 className={style.h2}>О технологии</h2>
            <p>
                Методом плоского шлифования обрабатываются плоские поверхности и его часто применяют при изготовлении технической оснастки: пресс-формы, приспособления для обработки направляющих станин металлорежущих станков. Плоское шлифование происходит на электромагнитных плитах или специализированных рабочих столах
            </p>

            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Размер рабочего поля</td> <td> Ширина 990 мм, длина 280 мм, высота 400 мм</td> </tr>
                    <tr> <td>Максимальные размеры обрабатываемой заготовки</td> <td> Ширина 630 мм, длина 280 мм, высота 400 мм</td> </tr>
                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание.
            </p>

            <div className={style.button}>
                <Button
                    buttonStyle={"btn--yellow"}
                    buttonSize={"btn--l"}
                    // IconRight={'phone'}
                    // IconColor={'var(--second)'}
                    onClick={() => buttonHandler(form)}
                >Получить расчет стоимости
                </Button>
            </div>

        </>
    );
};
