import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css'
import { Logo } from '../Elements/Logo';
import { Button, WhatsApp, Telegram, CallButton, WhatsAppButton, TelegramButton } from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { FormCall } from '../Form/FormCall'
import { CardServiceSmall } from '../Card/CardService';
import { useGetDoc } from '../../Hooks/UseGetDoc'
import { useAuth } from '../../Authentication/AuthContext';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

const Styles = [
    'posRel',
    'posAbs'
]

export const Header = ({ position, service }) => {

    const headerPosition = Styles.includes(position) ? position : [0]
    const [openMenu, setOpenMenu] = useState(false)

    const [openCallForm, setOpenCallForm] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)


    return (

        <header className={`content ${headerPosition}`}>


            {openCallForm
                ?
                <Modal
                    setActive={setOpenCallForm}
                    submitted={submitted}
                    loading={loading} >
                    <div className='popupMessenger'>
                        <WhatsApp />
                        <Telegram />
                    </div>
                    <FormCall
                        setSubmitted={setSubmitted}
                        setLoading={setLoading}
                        loading={loading} />
                </Modal>
                : null
            }


            <div className='headerContainer'>

                <div className='aboveHeader'>
                    <div className='btnAbove'>
                        <span className='btnAboveCall'><HeaderCallBatton openCallForm={openCallForm} setOpenCallForm={setOpenCallForm} /></span>
                        <span className='btnAboveMes'><HeaderMessenger /></span>
                        <span className='btnAboveConnect'><Connect openCallForm={openCallForm} setOpenCallForm={setOpenCallForm} /></span>
                    </div>
                </div>

                <div className='header'>
                    <div className='logo'><Link to="/"> <Logo /> </Link></div>
                    <div className='navHeader'> <Menu /> </div>
                    <div className='headerButtons'>
                        <span className='btnCall'><HeaderCallBatton openCallForm={openCallForm} setOpenCallForm={setOpenCallForm} /></span>
                        <span className='btnMes'><HeaderMessenger /></span>
                    </div>
                    <img
                        className='burger'
                        src="/img/icon/BurgerIcon.svg"
                        onClick={() => setOpenMenu(!openMenu)}
                    />
                    <BurgerMenu
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                        openCallForm={openCallForm}
                        setOpenCallForm={setOpenCallForm}
                    />
                </div>
                {service
                    ?
                    <Breadcrumbs levelTwo={'Услуги'} levelTwoURL='services' levelThree={service?.serviceName} />
                    : null
                }
            </div>
        </header>
    );
};


export const Menu = () => {

    const { docs } = useGetDoc('services')
    const { user } = useAuth()

    const dropdownMenu = docs.filter(doc => doc.category !== 'drill')

    return (
        <>
            <div className='dropdown'>
                <NavLink
                    className={({ isActive }) => isActive ? 'linkActive' : 'link'}
                    to="/services"><p className='navUnit'>Услуги</p></NavLink>

                <div className='dropdownContent'>
                    <div className='dropdownServiceCards'>
                        {dropdownMenu.map(service => <CardServiceSmall service={service} key={service.name} />)}
                    </div>
                </div>
            </div>

            {/* <NavLink
                className={({ isActive }) => isActive ? `linkActive` : `link`}
                to="/about"><p className='navUnit'>О компании</p>
            </NavLink> */}

            <NavLink
                className={({ isActive }) => isActive ? `linkActive` : `link`}
                to="/contact"><p className='navUnit'>Контакты</p>
            </NavLink>

            {user
                ?
                <NavLink
                    className={({ isActive }) => isActive ? `linkActive` : `link`}
                    to="/admin"><p className='navUnit'>Админ</p>
                </NavLink>
                : null
            }
        </>
    );
};




const BurgerMenu = ({
    openMenu,
    setOpenMenu,
    openCallForm,
    setOpenCallForm
}) => {

    const { docs } = useGetDoc('services')
    const [services, setServices] = useState(false)
    const dropdownMenu = docs.filter(doc => doc.category !== 'drill')


    return (
        <div className={openMenu ? 'overleyNav active' : 'overleyNav'}>
            <div className={openMenu ? 'contentNav active' : 'contentNav'} onClick={(e) => e.stopPropagation()}>

                <div className='cancel_mobileMenu' onClick={() => setOpenMenu(false)} >
                    <img src="img/icon/Cancel.svg" />
                </div>

                <div className='burgerMenu'>
                    <Button
                        buttonStyle={"btn--white"}
                        buttonSize={"btn--l"}
                        IconRight={'connect'}
                        IconColor={'var(--second)'}
                        onClick={() => setServices(!services)}
                    >Услуги
                    </Button>
                    {services
                        ?
                        <div className='dropdownBurgerMenu'>
                            {dropdownMenu.map(service => <CardServiceSmall service={service} key={service.name} />)}
                        </div>
                        : null
                    }

                    {/* <a href="/about">
                        <Button
                            buttonStyle={"btn--grey"}
                            buttonSize={"btn--l"}
                            IconRight={'arrowRight'}
                            IconColor={'var(--second)'}
                        >О компании
                        </Button>
                    </a> */}

                    <a href="/contact">
                        <Button
                            buttonStyle={"btn--grey"}
                            buttonSize={"btn--l"}
                            IconRight={'arrowRight'}
                            IconColor={'var(--second)'}
                        >Контакты
                        </Button>
                    </a>
                </div>

                <div className='burgerMenuContact'>
                    <Button
                        type={'button'}
                        buttonStyle={"btn--yellow"}
                        buttonSize={"btn--s"}
                        IconRight={'phone'}
                        IconColor={'var(--second)'}
                        onClick={() => setOpenCallForm(!openCallForm)}
                    >Заказать звонок
                    </Button>

                    <CallButton
                        buttonStyle={"btn--black"}
                        buttonSize={"btn--s"}
                        IconRight={'phone'}
                        IconColor={'#777'}
                    />

                    <WhatsAppButton
                        buttonStyle={"btn--black"}
                        buttonSize={"btn--s"}
                        IconRight={'whatsapp'}
                        IconColor={'#777'}
                    />

                    <TelegramButton
                        buttonStyle={"btn--black"}
                        buttonSize={"btn--s"}
                        IconRight={'telegram'}
                        IconColor={'#777'}
                    />
                </div>

            </div>

        </div>
    );
};



const HeaderMessenger = () => {
    return (
        <div className='headerMessenger'>
            <WhatsApp />
            <Telegram />
        </div>
    );
};


const HeaderCallBatton = ({ openCallForm, setOpenCallForm }) => {
    return (
        <div className='headerCallBattons'>
            <Button
                type={'button'}
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--s"}
                IconRight={'phone'}
                IconColor={'var(--second)'}
                onClick={() => setOpenCallForm(!openCallForm)}
            >Заказать звонок
            </Button>
            <CallButton
                buttonStyle={"btn--grey"}
                buttonSize={"btn--s"}
                IconRight={'phone'}
                IconColor={'var(--text)'}
            />
        </div>

    );
};


const Connect = ({ openCallForm, setOpenCallForm }) => {
    return (

        <Button
            type={'button'}
            buttonStyle={"btn--grey"}
            buttonSize={"btn--s"}
            IconRight={'connect'}
            IconColor={'var(--second)'}
            onClick={() => setOpenCallForm(!openCallForm)}
        >Связаться с нами
        </Button>


    );
};