import style from './Services.module.css'
import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../Components/HeaderFooter/Header';
import { CardService } from '../Components/Card/CardService';
import { Footer } from '../Components/HeaderFooter/Footer';
import { useGetDoc } from '../Hooks/UseGetDoc';
import { Content } from '../Components/Layout/Content';


export const Services = () => {

    const { docs } = useGetDoc('services')

    useEffect(() => {
        document.body.style.backgroundColor = 'var(--second)';
    }, []);


    const refCNC = useRef()
    const refAbrasion = useRef()
    const refDrill = useRef()

    const buttonHandler = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const buttons = [
        {
            name: 'Работы на станках с ЧПУ',
            block: refCNC,
        },
        {
            name: 'Шлифовка',
            block: refAbrasion,
        },
        {
            name: 'Сверление и долбление',
            block: refDrill,
        },
    ]



    return (
        <>
            <Header />

            <main>

                <Content>
                    <div className={style.services}>
                        <h2>Работы на станках с ЧПУ</h2>
                        <div className={style.cards} ref={refCNC}>
                            {docs.filter(unit => unit.category === 'cnc').map(service => <CardService service={service} key={service.id} />)}
                        </div>
                    </div>
                </Content>



                <Content backgroundColor={'var(--blackLight)'} ref={refAbrasion}>
                    <div className={style.services} ref={refAbrasion}>
                        <h2>Шлифовка</h2>
                        <div className={style.cards}>
                            {docs.filter(unit => unit.category === 'abrasion').map(service => <CardService service={service} key={service.id} />)}
                        </div>
                    </div>
                </Content>


                {/* <Content ref={refDrill}>
                    <div className={style.services}>
                        <h2>Сверление / Долбление</h2>
                        <div className={style.cards}>
                            {docs.filter(unit => unit.category === 'drill').map(service => <CardService service={service} key={service.id} />)}
                        </div>
                    </div>
                </Content> */}

            </main>

            <Footer />
        </>

    );
};




{/* <div className={style.buttons}>
                        {buttons.map(button =>
                            <div
                                className={style.button}
                                onClick={() => { buttonHandler(button.block); }
                                }>
                                <h6>{button.name}</h6>
                            </div>
                        )}
                    </div> */}