import React, { useState } from 'react';
import FirebaseData from '../../Firebase/firebase-data';
import { Button } from '../Button/Button';
import style from './Form.module.css'
import { Checkbox } from './FormUnit/Checkbox';
import { Input } from './FormUnit/Input';
import { DataForm } from './DataForm';



export const FormCall = ({ center, setSubmitted, setLoading, loading}) => {

    const { name, phone, city, personalData } = DataForm
    const [message, setMessage] = useState({ error: false, msg: '' })

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const [values, setValues] = useState({
        name: '',
        phone: '',
        city: '',
        date,
        typeForm: 'callFeedback'
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const newOrder = {
            ...values
        }
        try {
            await FirebaseData.addOrders(newOrder)
            setMessage({ error: false, msg: 'Заявка отправлена, наш менеджер свяжется с вами в ближайшее время' })
        } catch (error) {
            console.log(error.message)
        }

        setSubmitted(true);
        setLoading(false)
        setTimeout(function () {
            window.location.reload();
        }, 3000);
    }


    return (
        <form className={center ? style.formContainer : null} onSubmit={handleSubmit}>
            <div className={style.formBG}>
                <div className={style.form}>
                    <Input {...name}
                        value={values[name.name]}
                        onChange={onChange}
                    />
                    <Input {...phone}
                        value={values[name.phone]}
                        onChange={onChange}
                    />
                    <Input {...city}
                        value={values[name.city]}
                        onChange={onChange}
                    />

                </div>
                <div className={style.sumbit}>
                    <Checkbox
                        id={'call'}
                        {...personalData}
                    />
                    <div className={style.buttonContainer}>
                        <Button
                            type={'submit'}
                            disabled={loading}
                            buttonStyle={"btn--yellow"}
                            buttonSize={"btn--m"}
                        >Заказать звонок
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};
