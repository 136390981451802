import React, { useEffect, useRef } from 'react';
import style from './Homepage.module.css'
import { Footer } from '../Components/HeaderFooter/Footer';
import { Header } from '../Components/HeaderFooter/Header';
import { CardServiceBlack } from '../Components/Card/CardService'
import { ExampleWork } from '../Components/Blocks/ExampleWork';
import { useGetDoc } from '../Hooks/UseGetDoc';
import { Content } from '../Components/Layout/Content';
import { Form } from '../Components/Form/Form';
import { Button } from '../Components/Button/Button';
import { Title } from '../Components/Title/Title';
import { HowWeWork } from '../Components/Blocks/HowWeWork';
import { Collaboration } from '../Components/Blocks/Collaboration';


export const Homepage = () => {

    const { docs } = useGetDoc('services')

    const services = docs.filter(doc => doc.category !== 'drill')

    useEffect(() => {
        document.body.style.backgroundColor = '#fff';
    }, []);

    const form = useRef()
    const buttonHandler = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };


    return (
        <>
            <Header position={'posAbs'} />

            <main>

                <div className={style.firstBlock}>
                    <div className={style.cover}>
                        <div className={style.firstBlocktTitle}>
                            <h1>Высокоточное серийное производство деталей из металла</h1>
                            <p className={style.firstBlocktDescription}>Компания «ПромСтройСервис» производит детали из металла с точностью до 0,001 мм</p>
                            <Button
                                buttonStyle={"btn--white"}
                                buttonSize={"btn--l"}
                                onClick={() => buttonHandler(form)}
                            // IconRight={'connect'}
                            // IconColor={'var(--second)'}
                            >Получить расчет стоимости
                            </Button>
                        </div>
                        <div className={style.tonerImg}></div>
                    </div>
                </div>
                <Content>
                    <p className={style.description}>
                        Основное направление Промстройсервис — серийная металлообработка и изготовление деталей на заказ по индивидуальным чертежам на токарых станках с ЧПУ. Наш опыт, высокая квалификация и современное оборудование гарантируют качество и высокую точность изготовленного изделия. <br/><br/>
                        
                        Наше производство находиться в небольшом промышленном городе Республики Башкортостан. Но наши основные постоянные заказчики токарных работ на ЧПУ находятся не только в нашем регионе, но и за его пределами, с самой разной удаленностью.</p>
                </Content>

                <HowWeWork />

                <Content backgroundColor={'var(--second)'}>
                    <Title
                        title={'Наши услуги'}
                        color={'white'}
                    />
                    <div className={style.services}>
                        {services.map(service => <CardServiceBlack
                            service={service}
                            key={service.id}
                        />)}
                    </div>
                </Content>

                <ExampleWork />
                <Collaboration />

                <Content>
                    <div className={style.form} ref={form}>
                        <Form center={true} />
                    </div>
                </Content>

            </main>

            <Footer />
        </>
    );
};
