import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const Gidro = ({ buttonHandler, form }) => {
    return (
        <>
            <h2 className={style.h2}>О технологии</h2>
            <ul className={style.list}>
                {[
                    'Экономьте материалы до 30% благодаря фигурному раскрою деталей',
                    'Технология позволяет получать сложные готовые изделия без последующей обработки или с минимальной доработкой',
                    'Сверление отверстий в материалах с точностью 0,07 мм',
                    'Изготовление изделий сложной формы и фактуры поверхности в широком диапазоне размеров.',
                ].map(li => <li>{li}</li>)}
            </ul>
            <h2 className={style.h2}>Преимущества гидроабразивной резки</h2>
            <h3 className={style.h3}>Отсутствие зоны термического влияния</h3>
            <p>Зона термического влияния — побочный продукт большинства процессов горячей резки.</p>
            <h3 className={style.h3}>Готовые детали высокого класса качества</h3>
            <p>ЗРезка гидроабразивом обеспечивает высокое качество изделия, с гладкими кромками, без заусенец. Вырезанные детали не требуют последующей обработки, что снижает общую стоимость изготовления.</p>
            <h3 className={style.h3}>Отсутствие необходимости в смене инструмента</h3>
            <p>Гидроабразивный станок не применяет режущих инструментов и нет необходимости менять сопло для обработки различных материалов и толщин. Одно сопло используется для различных задач путем регулировки параметров потока.</p>
            <h3 className={style.h3}>Экономическая эффективность</h3>
            <p>Метод гидроабразивной резки является экономически выгодным в сравнении с другими методами резки. Процесс часто не требует дополнительных приспособлений, чем увеличивает скорость производства.</p>
            <h3 className={style.h3}>Скорость изготовления</h3>
            <p>Плюсом гидроабразивной резки  является возможность укладки и резки сразу  нескольких слоев материала за один проход и минимальное количество отходов материала.</p>
            <h3 className={style.h3}>Работа с различными материалами</h3>
            <p>Возможность резать широкий спектр материалов при условии правильного выбора параметров процесса и абразивных материалов.</p>
            <h3 className={style.h3}>Экологичность воздействия на окружающую среду</h3>
            <p>Вода — является основным материалом для метода. И большое преимущество резки гидроабразивом то, что использованная вода подлежит дальнейшей вторичной переработке. В процессе также не применяются охлаждающие масла, водяная струя сама выступает в качестве охлаждающей жидкости.</p>


            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Максимальный размер рабочего стола</td> <td> Ширина 600 мм, длина 800 мм, высота 800 мм</td> </tr>
                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание. Cрок выполнения зависят от:
            </p>
            <ul className={style.list}>
                {[
                    'Количества деталей в заказе',
                    'Габариты изделий',
                    'Необходимость предварительной обработки заготовок',
                    'Сложность самих деталей',
                ].map(li => <li>{li}</li>)}
            </ul>

            <Button
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--l"}
                // IconRight={'phone'}
                // IconColor={'var(--second)'}
                onClick={() => buttonHandler(form)}
            >Получить расчет стоимости
            </Button>

        </>
    );
};
