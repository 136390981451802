import React from 'react';
import './Button.css'


const Icons = [
  { icon: '/img/icon/ArrowRightSmallBlack.svg', id: 'arrowRight', },
  { icon: '/img/icon/phone.svg', id: 'phone', },
  { icon: '/img/icon/Messenger_TelegramFlat.svg', id: 'telegram', },
  { icon: '/img/icon/Messenger_WhatsappFlat.svg', id: 'whatsapp', },
  { icon: '/img/icon/ArrowСonnect.svg', id: 'connect', },



]

const Styles = [
  "btn--white",
  "btn--black",
  "btn--grey",
  "btn--yellow",
  'btn--telegram',
  'btn--whatsapp',
]

const Sizes = [
  "btn--l",
  "btn--m",
  "btn--s",
]

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  IconLeft,
  IconRight,
  IconColor,
  disabled }) => {

  const checkButtonStyle = Styles.includes(buttonStyle) ? buttonStyle : [0]
  const checkButtonSize = Sizes.includes(buttonSize) ? buttonSize : [0]
  const IconLeftIndex = Icons.findIndex(icon => icon.id === IconLeft)
  const IconRightIndex = Icons.findIndex(icon => icon.id === IconRight)

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {IconLeft
        ? <div className='icon'>
          <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: `${IconColor}`,
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            maskImage: `url(${Icons[IconLeftIndex]?.icon})`,
            webkitMaskImage: `url(${Icons[IconLeftIndex]?.icon})`,

          }} />
        </div>
        : null
      }
      <p className='text-button'>{children}</p>
      {IconRight
        ? <div className='icon'>
          <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: `${IconColor}`,
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            maskImage: `url(${Icons[IconRightIndex]?.icon})`,
            webkitMaskImage: `url(${Icons[IconRightIndex]?.icon})`,

          }} />
        </div>
        : null
      }
    </button>
  );
};

export const WhatsApp = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'https://chat.whatsapp.com/FfpcLCpvJvoDXzQiWchN0Q'} target="_blank" className='roundButton'
      >
        <img src="/img/icon/messenger_WhatsApp.svg" alt="" style={{ display: 'block' }} />
      </a>
    </>
  );
};

export const WhatsAppButton = ({ link, IconRight, IconColor, buttonSize, buttonStyle }) => {

  return (
    <>
      <a href={link ? link : 'https://chat.whatsapp.com/FfpcLCpvJvoDXzQiWchN0Q'} target="_blank">
        <Button
          type="button"
          buttonStyle={buttonStyle}
          buttonSize={buttonSize}
          IconRight={IconRight}
          IconColor={IconColor}
        >Группа WhatsApp
        </Button>
      </a>
    </>
  );
};

export const Telegram = ({ link }) => {

  return (
    <>
      <a href={link ? link : 'https://t.me/+pTApAYs2oa9hYTli'} target="_blank" className='roundButton'
      >
        <img src="/img/icon/messenger_Telegram.svg" alt="" style={{ display: 'block' }} />
      </a>
    </>
  );
};

export const TelegramButton = ({ link, IconRight, IconColor, buttonSize, buttonStyle }) => {

  return (
    <>
      <a href={link ? link : 'https://t.me/+pTApAYs2oa9hYTli'} target="_blank">
        <Button
          type="button"
          buttonStyle={buttonStyle}
          buttonSize={buttonSize}
          IconRight={IconRight}
          IconColor={IconColor}
        >Группа Telegram
        </Button>
      </a>
    </>
  );
};

export const CallButton = ({ link, IconRight, IconColor, buttonSize, buttonStyle }) => {

  return (
    <>
      <a href={link ? link : 'tel:+79997617025'} target="_blank">
        <Button
          type="button"
          buttonStyle={buttonStyle}
          buttonSize={buttonSize}
          IconRight={IconRight}
          IconColor={IconColor}
        >8 999 761 70 25
        </Button>
      </a>
    </>
  );
};
