import React, { useEffect } from 'react';
import { Button, TelegramButton, WhatsAppButton } from '../Components/Button/Button';
import { Footer } from '../Components/HeaderFooter/Footer';
import { Header } from '../Components/HeaderFooter/Header';
import style from './Contact.module.css'
import { Content } from '../Components/Layout/Content'

export const Contact = () => {

    useEffect(() => {
        document.body.style.backgroundColor = 'var(--second)';
    });

    return (
        <>
            <Header />

            <main>

                <Content>
                    <div className={style.content}>

                        <div className={style.maps}>
                            <div className={style.place}>
                                <h6><img src="/img/icon/mapsPoint.svg" />Учалинский район, Республика Башкортостан</h6>
                            </div>
                        </div>

                        <div className={style.contacts}>

                            <div className={style.text}>
                                <div className={style.adress}>
                                    <p>г. Учалы К. Маркса 2, офис 13</p>
                                    <p>Россия, Республика Башкортостан, <br /> Учалинский район</p>
                                </div>
                                <div className={style.contact}>
                                    <div className={style.phone}>
                                        <img src="/img/icon/phone.svg" />
                                        <p>8 999 761-70-25</p>
                                    </div>
                                    <div className={style.phone}>
                                        <img src="/img/icon/phone.svg" />
                                        <p>8 999 761-70-39</p>
                                    </div>

                                    <div className={style.mail}>
                                        <img src="/img/icon/mail.svg" />
                                        <p>pstroyservise@gmail.com</p>
                                    </div>

                                </div>
                            </div>


                            <div className={style.button}>
                                <WhatsAppButton
                                    buttonStyle={"btn--white"}
                                    buttonSize={"btn--s"}
                                    IconRight={'whatsapp'}
                                    IconColor={'#02CF6A'}
                                />
                                <TelegramButton
                                    buttonStyle={"btn--white"}
                                    buttonSize={"btn--s"}
                                    IconRight={'telegram'}
                                    IconColor={'#26A3E1'}
                                />
                            </div>
                        </div>
                    </div>
                </Content>

                <div className={style.yandexMaps}>
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A69fb2b5be158e1d08a19d6d066aec5b2cd983739210621bffde5059f1a8def6c&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
                </div>

            </main>

            <Footer />
        </>
    );
};