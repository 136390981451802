import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, CallButton, TelegramButton, WhatsAppButton, WhatsApp, Telegram } from '../Button/Button';
import { Logo } from '../Elements/Logo';
import style from './Footer.module.css'
import { Menu } from './Header';
import { GraphicDigger } from '../../Components/GraphicDigger/GraphicDigger'
import { FormCall } from '../Form/FormCall';
import { Modal } from '../Modal/Modal';

export const Footer = () => {

    const [openCallForm, setOpenCallForm] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
        <footer>

            {openCallForm
                ?
                <Modal
                    setActive={setOpenCallForm}
                    submitted={submitted}
                    loading={loading} >
                    <div className='popupMessenger'>
                        <WhatsApp />
                        <Telegram />
                    </div>
                    <FormCall
                        setSubmitted={setSubmitted}
                        setLoading={setLoading}
                        loading={loading} />
                </Modal>
                : null
            }

            <div className={style.footer}>

                <div className={style.content}>
                    <div className={style.navFooter}>
                        <Link to="/" className={style.logo} >
                            <Logo />
                        </Link>

                        <div className={style.services}>
                            <a href="/services">
                                <Button
                                    type={'button'}
                                    buttonStyle={"btn--white"}
                                    buttonSize={"btn--l"}
                                    IconRight={'arrowRight'}
                                    IconColor={'var(--second)'}
                                >Все уcлуги
                                </Button>
                            </a>
                        </div>
                        <div className={style.buttons}>
                            <Button
                                type={'button'}
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--s"}
                                IconRight={'phone'}
                                IconColor={'#777'}
                                onClick={() => setOpenCallForm(!openCallForm)}
                            >Заказать звонок
                            </Button>
                            <CallButton
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--s"}
                                IconRight={'phone'}
                                IconColor={'#777'}
                            />
                            {/* <WhatsAppButton
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--s"}
                                IconRight={'whatsapp'}
                                IconColor={'#777'}
                            />
                            <TelegramButton
                                buttonStyle={"btn--black"}
                                buttonSize={"btn--s"}
                                IconRight={'telegram'}
                                IconColor={'#777'}
                            /> */}
                            <WhatsApp />
                            <Telegram />
                        </div>
                    </div>
                    <div className={style.copyright}>
                        <p>Политика конфиденциальности</p>
                        <p>ООО ПромСтройСервис, 2022</p>
                        <GraphicDigger />
                    </div>
                </div>
            </div>

        </footer>
    );
};