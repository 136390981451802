import React, { useEffect, useState, Children, cloneElement, useRef } from 'react';
import { Context } from '../../Context/Context';
import style from './Slider.module.css'

export const Slider = ({ children, infinite }) => {

    const [slides, setSlides] = useState([]);
    const [width, setWidth] = useState(640);
    const [height, setHeight] = useState(400);
    const [offset, setOffset] = useState(-width/1.1);

    const refSliderWindow = useRef()

    useEffect(() => {

        const handlerResize = () => {
            const _width = refSliderWindow.current.offsetWidth / 1.6
            const _height = _width / 1.7
            setWidth(_width)
            setHeight(_height)
        }
        handlerResize()
        window.addEventListener('resize', handlerResize)
        return () => {
            window.removeEventListener('resize', handlerResize)
        }
    }, []);


    const handleLeft = () => {
        setOffset((current) => {
            const newOffset = current + width
            return Math.min(newOffset, 0)
        })
    }

    const handleRight = () => {
        setOffset((current) => {
            const newOffset = current - width
            const maxOffset = -(width * (slides.length - 1))
            return Math.max(newOffset, maxOffset)
        })
    }

    useEffect(() => {
        setSlides(
            Children.map(children, child => {
                return cloneElement(child, {
                    style: {
                        height: '100%',
                        maxWidth: `${width}px`,
                        minWidth: `${width}px`,

                    }
                })
            })
        )
    }, []);



    return (
        <Context.Provider value={{ width, height }}>
            <div className={style.slider}>
                <div className={style.container}>
                    <div className={style.window} ref={refSliderWindow}>
                        <div
                            className={style.slides}
                            style={
                                { transform: `translateX(${offset}px)` }
                            }
                        >
                            {slides}
                        </div>
                    </div>
                </div>

                <div className={style.buttons}>
                    <div onClick={handleLeft} className={ `${style.button} ${style.left}`}></div>
                    <div onClick={handleRight} className={ `${style.button} ${style.right}`}></div>
                </div>
            </div>
        </Context.Provider>
    );
};
