import React from 'react';
import { Link } from 'react-router-dom';
import style from './CardService.module.css'


export const CardService = ({ service }) => {

    return (
        <a href={`services/${service.link}`}>
            <div className={style.card}>
                <div className={style.cardWhite}>
                    <div className={style.content}>
                        <div className={style.title}><p>{service.name}</p></div>
                        <div className={style.image}>
                            <img src={service.cover} />
                        </div>
                    </div>
                    <div className={style.arrow}>
                        <img src="img/icon/Arrow_Right_Big.svg" style={{ width: '24px', margin: '24px' }} />
                    </div>
                </div>
            </div>
        </a>
    );
};


export const CardServiceBlack = ({ service }) => {

    return (
        <a href={`services/${service.link}`}>
            <div className={style.card}>
                <div className={style.cardBlack}>
                    <div className={style.content}>
                        <div className={style.titleWhite}><p>{service.name}</p></div>
                        <div className={style.image}>
                            <img src={service.cover} />
                        </div>
                    </div>
                    <div className={style.arrow}>
                        <img src="/img/icon/ArrowRightBigWhite.svg" style={{ width: '24px', margin: '24px' }} />
                    </div>
                </div>

            </div>
        </a>
    );
};

export const CardServiceSmall = ({ service }) => {

    return (
        <a href={`/services/${service.link}`}>
            <div className={style.serviceSmall}>
                <p>{service.name}</p>
                <div className={style.serviceSmallImage}>
                    <img src={service.cover} />
                </div>

            </div>
        </a>
    );
};



