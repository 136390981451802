import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { Content } from '../Layout/Content';
import { Modal } from '../Modal/Modal';
import { Title } from '../Title/Title';
import style from './Blocks.module.css'

const stages = [
    {
        icon: '/img/icon/howWeWork_chertezh.png',
        description: 'Вы присылаете чертеж или образец детали',
        button: true
    },
    {
        icon: '/img/icon/howWeWork_docs.png',
        description: 'Проектировщики готовят документацию (если нет чертежей)',
        button: false
    },
    {
        icon: '/img/icon/howWeWork_technology.png',
        description: 'Инженеры выбирают оптимальную технологию изготовления детали (учитывая количество единиц, вид металла и специфику конфигурации)',
        button: false
    },
    {
        icon: '/img/icon/howWeWork_regions.png',
        description: 'Готовая продукция отправляется в любой регион России',
        button: false
    }
]

export const HowWeWork = () => {

    const [openCallForm, setOpenCallForm] = useState(false)

    return (
        <Content>

            {
                openCallForm
                    ?
                    <Modal
                        setActive={setOpenCallForm}>
                        <div className={style.modal}>
                            <ul className={style.list}>
                                {[
                                    'В чертеже должны быть указаны: материал заготовки, размеры, допуски и требования к поверхности после обработки',
                                    'Чертеж должен быть выполнен в электронном виде, в векторном формате (Autocad, Компас). При необходимости мы осуществляем разработку чертежей по представленным эскизам',
                                    'Если у вас нет возможности предоставить чертёж в векторных форматах, допускается  эскиз детали от руки со всеми размерами и допусками (время изготовления незначительно увеличится)',
                                    'Вы можете предоставить нам образец для последующего его изготовления',
                                ].map(li => <li>{li}</li>)}
                            </ul>
                        </div>
                    </Modal>
                    : null
            }

            <Title title={'Как мы работаем'} />
            <div className={style.stages}>
                {stages.map(stage =>
                    <div className={style.stage}>
                        <div className={style.icon}>
                            <img src={stage.icon} />
                        </div>
                        <p>{stage.description}</p>
                        {stage.button
                            ?
                            <Button
                                type={'button'}
                                buttonStyle={"btn--yellow"}
                                buttonSize={"btn--s"}
                                onClick={() => setOpenCallForm(!openCallForm)}
                            >Требования к чертежам
                            </Button>
                            : null
                        }
                    </div>
                )}
            </div>
        </Content>
    );
};
