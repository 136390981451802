import React from 'react';
import { Content } from '../Layout/Content';
import { Title } from '../Title/Title';
import style from './Blocks.module.css'

const collaboration = [
    {
        icon: '/img/icon/collaboration_tochnost.png',
        description: 'Современное металлообрабатывающее оборудование и режущий инструмент, что позволяет достичь необходимого класса точности детали вне зависимости сложности ее изготовления и геометрии',
    },
    {
        icon: '/img/icon/collaboration_personal.png',
        description: 'Высокая квалификация и компетентность персонала, который контролирует процедуру обработки детали на всех этапах технологического процесса',
    },
    {
        icon: '/img/icon/collaboration_individualProject.png',
        description: 'Возможность заказать изготовление нестандартных деталей по индивидуальному проекту',
    },
    {
        icon: '/img/icon/collaboration_price.png',
        description: 'Доступная стоимость услуг и соблюдение прописанных в договоре сроков',
    }
]

export const Collaboration = () => {


    return (
        <Content backgroundColor={'var(--second)'}>
            <Title
                title={'Плюсы сотрудничества с нами'}
                color={'white'}
            />
            <div className={style.stages}>
                {collaboration.map(stage =>
                    <div className={style.stage}>
                        <div className={style.icon}>
                            <img src={stage.icon} />
                        </div>
                        <p style={{ color: 'white' }}>{stage.description}</p>
                    </div>
                )}
            </div>
        </Content>
    );
};