import React from 'react';
import style from './FormUnit.module.css'

export const TextArea = ({
    onChange,
    placeholder,
    label,
    ...inputProps }) => {



    return (
        <div>
            {/* <p className={style.textareaLabel}>{label}</p> */}
            <textarea className={style.textarea}
                {...inputProps}
                onChange={onChange}
                placeholder={placeholder}
            ></textarea>
        </div>
    );
};