import React from 'react';
import './Modal.css'
import { Loader } from '../Loader/Loader'

export const Modal = ({ children, loading, setActive, submitted }) => {




    return (
        <div className='modal active' onClick={() => setActive(false)}>
            <div className='modal-container' onClick={(e) => e.stopPropagation()}>
                {loading
                    ? <div className='spinner'><Loader /></div>
                    :
                    <>
                        {submitted
                            ?
                            <>
                                <div className='feedback'>
                                    <img src="/img/icon/Submitted.svg" alt="" style={{ width: '60px' }} />
                                    <p className='orderTitle'>Заявка отправлена</p>
                                    <p className='orderDescription'>Мы с вами свяжемся в ближайшее время</p>
                                </div>
                            </>
                            :
                            <div className='modal-content'>{children}</div>
                        }
                    </>
                }
                <div className='cancel-modal' onClick={() => setActive(false)}>
                    <img src="/img/icon/cancel_small.svg" alt="" />
                </div>
            </div>
        </div>
    );
};

