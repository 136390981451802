import { v4 as uuidv4 } from 'uuid'


export const DataForm = {
    name:
    {
        id: uuidv4(),
        name: 'name',
        placeholder: ' ',
        label: 'Ваше имя',
        errorMessage: 'Укажите ваше имя',
        required: true,
    },
    city:
    {
        id: uuidv4(),
        name: 'city',
        placeholder: ' ',
        label: 'Город',
        errorMessage: 'Из какого вы города?',
        required: true,
    },
    company:
    {
        id: uuidv4(),
        name: 'company',
        placeholder: ' ',
        label: 'Название компании',
        errorMessage: 'Укажите название компании',
        required: true,
    },
    phone:
    {
        id: uuidv4(),
        name: 'phone',
        placeholder: ' ',
        label: 'Телефон',
        errorMessage: 'Укажите телефон для связи',
        required: true,
    },
    email:
    {
        id: uuidv4(),
        name: 'email',
        placeholder: ' ',
        label: 'Email',
        errorMessage: 'Укажите email',
        required: true,
    },
    comment:
    {
        id: uuidv4(),
        name: 'comment',
        placeholder: 'Комментарий',
        label: 'Комментарий',
    },
    upload:
    {
        id: uuidv4(),
        name: 'upload',
        label: 'Загрузить файл',
    },
    personalData:
    {
        // id: uuidv4(),
        name: 'personalData',
        label: 'Согласен ',
        required: true,
    },
    password:
    {
        id: uuidv4(),
        name: 'password',
        type: 'password',
        label: 'Пароль',
        errorMessage: 'Введите пароль',
        required: true,
        autoComplete: "on",
        placeholder: ' ',

    },

}