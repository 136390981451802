import React from 'react';
import style from './Slider.module.css'
import { useContext } from 'react';
import { Context } from '../../Context/Context';

export const Slide = ({ image }) => {

    const { width, height } = useContext(Context)

    return (
        <div className={style.img} style={{ minWidth: `${width}px`, maxWidth: `${width}px`, height: `${height}px` }}>
            <img cla src={image} />
        </div>
    );
};

