import { Services } from './Services/Services';
import { Route, Routes } from 'react-router-dom';
import { Contact } from './Contact/Contact';
import { About } from './About/About';
import { Homepage } from './HomePage/Homepage';
import { Singlepage } from './Singlepage/Singlepage';
import { Admin } from './Admin/Admin';
import { GetOrder } from './Admin/GetOrder';
import { GetCall } from './Admin/GetCall';
import { Login } from './Authentication/Login';
import { AuthContextProvider } from './Authentication/AuthContext';
import { RequireAuth } from './Authentication/RequireAuth'
import { Tokar } from './Singlepage/Technology/Tokar';

function App() {

  return (
    <>
      <AuthContextProvider>
        <Routes >
          <Route path='/' element={<Homepage />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='services' element={<Services />} />
          <Route path="services/:link" element={<Singlepage />} />
          <Route path='login' element={<Login />} />
          <Route path="admin" element={<RequireAuth><Admin /></RequireAuth>} />
          <Route path="admin/get-order" element={<GetOrder />} />
          <Route path="admin/get-call" element={<GetCall />} />
        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default App;
