import React from 'react';
import style from './GraphicDigger.module.css'

export const GraphicDigger = () => {
    return (
        <a
            target='_blank'
            href='https://graphicdigger.com/'
            className={style.graphicdigger}>

            Дизайн <span>graphic digger</span>

        </a>
    );
};