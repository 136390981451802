import { async } from '@firebase/util';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication/AuthContext';
import { Footer } from '../Components/HeaderFooter/Footer';
import { Header } from '../Components/HeaderFooter/Header';
import { Content } from '../Components/Layout/Content';
import style from './Admin.module.css'
import { GetCall } from './GetCall';
import { GetOrder } from './GetOrder';

const sideBarAdmin = [
    {
        id: 'getOrder',
        value: 'Заявка',
    },
    {
        id: 'getCall',
        value: 'Перезвонить',
    },
]

const orderForms = [
    {
        id: 'getOrder',
        form: <GetOrder />,
    },
    {
        id: 'getCall',
        form: <GetCall />,
    }
]

export const Admin = () => {

    useEffect(() => {
        document.body.style.backgroundColor = 'white';
    }, []);

    const { user, logOut } = useAuth()
    const navigate = useNavigate()
    const handleLogOut = async () => {
        try {
            await logOut()

        } catch (error) {
            console.log(error)
        }
    }

    const [selectOrder, setSelectOrder] = useState('getOrder')
    const [filteredOrder, setFilteredOrder] = useState([])
    const handleChangeOrder = (e) => {
        const { id } = e.target
        setSelectOrder(id)
    }
    useEffect(() => {
        setFilteredOrder(orderForms.find(unit => unit.id === selectOrder))
    }, [selectOrder]);

    return (
        <>
            <Header position={'posAbs'} />

            <main>
                <div className={style.content}>
                    <div className={style.sideBar}>
                        <div className={style.chapter}>
                            {sideBarAdmin.map(unit =>
                                <NavLink to={unit.link}>
                                    <p
                                        key={unit.id}
                                        id={unit.id}
                                        onClick={handleChangeOrder}
                                        className={style.sideBarLinkLabel}
                                    >
                                        {unit.value}
                                    </p>
                                </NavLink>
                            )}
                        </div>
                        <p onClick={handleLogOut}>Выйти</p>
                    </div>
                </div>
                <Content backgroundColor={'white'}>
                    <div className={style.dataContent}>
                        <div className={style.data}>
                            {filteredOrder?.form}
                        </div>
                    </div>
                </Content>
            </main>

        </>
    );
};
