import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const Plazma = ({ buttonHandler, form }) => {
    return (
        <>

            <h2 className={style.h2}>Технология HiFocus</h2>
            <h3 className={style.h3}>Преимущества плазменной резки:</h3>
            <ul className={style.list}>
                {[
                    'Возможность резать токопроводящие материалы',
                    'Хороший выбор для толщины до 50 мм',
                    'Максимальная толщина 150 мм',
                    'Возможность резать в воде, что приводит к уменьшению зоны термического влияния и снижение уровня шума',
                    'Полное соответствие изготовленного изделия заданным параметрам и ТЗ',
                    'Высокая скорость резки',
                ].map(li => <li>{li}</li>)}
            </ul>
            <h3 className={style.h3}>Недостатки плазменной резки</h3>
            <ul className={style.list}>
                {[
                    'Зона термического влияния больше чем в лазерной резке',
                    'Качество тонких листов и пластин хуже, чем при лазерной резке',
                    'Допуски не настолько точны, как при лазерной резке',
                    'Не достигает такой толщины, как гидроабразивной или газовой резке',
                    'Оставляет зону термического воздействия, которой полностью исключена при гидроабразивной резке',
                    'Более широкий пропил, чем при лазерной резке',
                    'Довольно сложный процесс, требующий высокой квалификации оператора',
                    'Расположение заготовки строго перпендикулярно',
                    'Выброс большого количества вредных газов в воздух во время резки металла',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Толщина листа</td> <td> 0,5 – 50 мм без образования бородок, с минимальной средней шероховатостью разреза и с большой точностью повтора. Максимальная толщина 150 мм.</td> </tr>
                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание. Cрок выполнения зависят от:
            </p>
            <ul className={style.list}>
                {[
                    'Количества деталей в заказе',
                    'Габариты изделий',
                    'Необходимость предварительной обработки заготовок',
                    'Сложность самих деталей',
                ].map(li => <li>{li}</li>)}
            </ul>

            <Button
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--l"}
                // IconRight={'phone'}
                // IconColor={'var(--second)'}
                onClick={() => buttonHandler(form)}
            >Получить расчет стоимости
            </Button>

        </>
    );
};
