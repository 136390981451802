import React from 'react';
import style from './Breadcrumbs.module.css'

export const Breadcrumbs = ({ levelTwo, levelTwoURL, levelThree, levelThreeURL }) => {
    return (
        <div className={style.breadcrumbs}>
            <div className={style.content}>
                <a href='/' className={style.home}>Главная</a>
                <p> – </p>
                <a href={`/${levelTwoURL}`} className={style.levelTwo} >{levelTwo}</a>
                {levelThree
                    ?
                    <>
                        <p> – </p>
                        <a className={style.levelThree} >{levelThree}</a>
                    </>
                    : null
                }
            </div >

        </div>

    );
};
