import React, { useEffect, useState } from 'react';
import { useGetDoc } from '../Hooks/UseGetDoc';
import style from './getData.module.css'
import FirebaseData from '../Firebase/firebase-data';



export const GetOrder = () => {

    const [services, setServices] = useState([])

    const getServices = async () => {
        const data = await FirebaseData.getAllServices()
        setServices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    useEffect(() => {
        getServices()
    }, []);

    const handlerDeleteProduct = async (id) => {
        await FirebaseData.deleteService(id)
        getServices()
    }

    const order = services.filter(unit => unit.typeForm === 'order')

    return (
        <div>

            <h2 className={style.title}> Заявки</h2>

            <table className={style.table}>
                <tr className={style.row}>
                    <th>Дата</th>
                    <th>Имя</th>
                    <th>Компания</th>
                    <th>Телефон</th>
                    <th>Email</th>
                    <th>Комментарий</th>
                    <th>Файл</th>
                </tr>

                {order.map((unit) => (
                    <tr key={unit.id} >
                        <td>{unit?.date}</td>
                        <td style={{ textAlign: 'center' }}>{unit?.name}</td>
                        <td style={{ textAlign: 'center' }}>{unit?.company}</td>
                        <td style={{ textAlign: 'center' }}>{unit?.phone}</td>
                        <td>{unit?.email}</td>
                        <td>{unit?.comment}</td>
                        <td style={{ textAlign: 'center' }}> <a href={unit?.file} target='_blank'>Скачать</a> </td>
                        <td><p className={style.delete} onClick={(e) => handlerDeleteProduct(unit.id)}>Удалить</p></td>
                    </tr>

                ))}
            </table>
        </div>
    );
};
