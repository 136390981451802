import React, { useRef } from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const Tokar = ({buttonHandler, form}) => {

    return (
        <>

            <h2 className={style.h2}>Токарные работы на станках с ЧПУ в Промстройсервис</h2>
            <p>
                Токарные работы с использованием станков с ЧПУ позволяют нам обрабатывать все виды металлов и сплавов. Мы производим самые разные изделия для крепежа, втулки, штуцеры и другие детали по вашему техническом заданию. После получения от заказчика технических параметров, мы разрабатываем программу и проводим тщательный контроль пробных образцов.
            </p>
            <h3 className={style.h3}>Преимущества токарных работ на станках с ЧПУ:</h3>
            <ul className={style.list}>
                {[
                    'Максимально возможная точность обработки',
                    'Низкие трудозатраты',
                    'Высокая скорость изготовления деталей, даже по индивидуальным проектам',
                    'Исключение человеческого фактора, как следствие, возможность ошибок при обработке деталей минимальная',
                    'Полное соответствие изготовленного изделия заданным параметрам и ТЗ',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Виды работ</h2>
            <ul className={style.list}>
                {[
                    'Обработка наружных и внутренних поверхностей цилиндрических деталей',
                    'Обработка наружных и внутренних поверхностей конической формы',
                    'Придание необходимых размеров внутренним/наружным поверхностям изделий',
                    'Обработка фасонных и торцевых поверхностей',
                    'Вытачивание канавок, пазов, уступов',
                    'Обработка торцов и кромок',
                    'Нарезка наружной и внутренней резьбы',
                    'Обработка отверстий, зенкерование',
                ].map(li => <li>{li}</li>)}
            </ul>

            <p>
                Токарные работы на ЧПУ производятся квалифицированными специалистами. Мы осуществляем высокий контроль точности формы и размеров готового изделия на соответствие параметрам, полученным от заказчика.
            </p>

            <h2 className={style.h2}>Виды оборудования</h2>
            <h3 className={style.h3}>Полуавтоматические станки</h3>
            <p>
                Для мелких и средних серий мы используем полуавтоматы с ЧПУ. На данном виде оборудования, система автоматов выполняет операции, где требуется высокая точность, чтобы исключить ошибки человеческого фактора, операторы же выполняют остальные, менее важные процессы.

            </p>
            <h3 className={style.h3}>Автоматические станки</h3>
            <p>
                Для изготовления больших партий, с деталями высокой точности, применяются полностью автоматизированный токарные станки с ЧПУ. Изготовление осуществляется полностью автоматами. Токарные работы на ЧПУ полной автоматизации идеально подходят для изготовления по индивидуальным проектам.
            </p>

            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Максимальный диаметр обработки</td> <td>до 1600мм</td> </tr>
                    <tr> <td>Максимальная длина детали</td> <td>до 1200мм</td> </tr>
                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание. Cрок выполнения зависят от:
            </p>
            <ul className={style.list}>
                {[
                    'Количества деталей в заказе',
                    'Габариты изделий',
                    'Необходимость предварительной обработки заготовок',
                    'Сложность самих деталей',
                ].map(li => <li>{li}</li>)}
            </ul>

            <Button
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--l"}
                // IconRight={'phone'}
                // IconColor={'var(--second)'}
                onClick={() => buttonHandler(form)}
            >Получить расчет стоимости
            </Button>

        </>
    );
};

