import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const ShlifKrug = ({ buttonHandler, form }) => {
    return (
        <>
            <h2 className={style.h2}>О технологии</h2>
            <p>
                При обработке, деталь устанавливается в цанговом или кулачковом патроне вращающегося шпинделя станка и в невращающихся центрах. В процессе шлифования, деталь и наждачный инструмент имеют заданные относительные движения, что обеспечивает резание материала.
            </p>

            <h2 className={style.h2}>Круглая наружняя шлифовка</h2>
            <p>
                Разновидность наружного шлифования — врезное плоское, когда инструмент взаимодействует с поверхностью заготовки по всей ее длине. Однако для этой технологии существует ограничения, ширина обрабатываемой поверхности не должна превышать 200 мм. <br /><br />
                Существует методика внешней круглой шлифовки с поперечной подачей, когда зерновая часть абразива удаляет верхний слой материала, а последующая обработка осуществляется во время кратчайшего перемещения круга.
                Наружное шлифование может быть комбинированным, когда при обработке одной детали используются оба типа подачи: продольный и поперечный.
                Процесс неоднороден степени точности. С учетом этого параметра выделяют внешнее шлифование:
            </p>
            <h3 className={style.h3}>Обдирочное шлифование</h3>
            <p>
                Самое грубое шлифование, обработка предшествующая шлифовке. Возможно выполннение и лезвийным инструментом, а при основных работах с металла снимается припуск до 5 мм.
            </p>
            <h3 className={style.h3}>Точное шлифование</h3>
            <p>
                Более искусное шлифование. Точная обработка с припуском до 3 мм.
            </p>
            <h3 className={style.h3}>Тонкое шлифование</h3>
            <p>
                Тонкое шлифование называют «ювелирной работой». Глубина врезки достигает 50 мкм.
            </p>

            <h2 className={style.h2}>Круглая наружняя шлифовка</h2>
            <p>
                Способом внутреннего шлифования обрабатываются цилиндрические, профильные и конические отверстия. В процессе осуществляется подача, вследствие вращения детали. Крупногабаритные заготовки остаются неподвижными, а шпиндель шлифовального круга совершает вращение вокруг оси обрабатываемого отверстия. <br/><br/>
                Шлифование с продольной подачей — один из самых востребованных и безупречных методов, который отличается идеально ровным и гладким качеством поверхности. Если в заготовке имеется глухое или сквозное, но не длинное отверстие, используется внутреннее круглое точение врезным и бесцентровым способами.

            </p>




            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание.
            </p>
            <div className={style.button}>
                <Button
                    buttonStyle={"btn--yellow"}
                    buttonSize={"btn--l"}
                    // IconRight={'phone'}
                    // IconColor={'var(--second)'}
                    onClick={() => buttonHandler(form)}
                >Получить расчет стоимости
                </Button>
            </div>
        </>
    );
};
