
import React from 'react';
import style from './Title.module.css'

export const Title = ({ title, color }) => {
    return (
        <>
            {color
                ?
                <h2
                    className={style.title}
                    style={{ color: `${color}` }}
                >
                    {title}</h2>

                :
                <h2
                    className={style.title}
                    style={{ color: `var(--second)` }}
                >
                    {title}</h2>
            }

            <div className={style.line}></div>

        </>
    );
};

