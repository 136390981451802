import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const Electro = ({ buttonHandler, form }) => {
    return (
        <>
            <h2 className={style.h2}>О технологии</h2>
            <p> Электроэрозионная резка применяется в случаях, когда заготовка не поддается фрезерной обработке: невыгодность или неудобство фрезерования. </p>
            <h3 className={style.h3}>Этапы процедуры обработки:</h3>
            <ol className={style.list}>
                {[
                    'Электрический ток, вырабатываемый генератором, поступает на молибденовую проволоку',
                    'Металл выжигается с помощью искровых разрядов, которые возникают в проволоке, а охлаждается специальным веществом',
                ].map(li => <li>{li}</li>)}
            </ol>
            <h3 className={style.h3}>Преимущества электроэрозионной резки</h3>
            <ul className={style.list}>
                {[
                    'Возможность создания острых углов сверхмалой градусной меры внутри и снаружи заготовки',
                    'Несложная обработка закаленной стали и других сплавов',
                    'Технология позволяет обработать все токопроводящие материалы',
                    'Возможность глубокой резки',
                    'Обработка закрытых каналов, которые не доступны для фрезерного станка',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Размер рабочего поля</td> <td> Ширина 600 мм, длина 800 мм, высота 800 мм</td> </tr>
                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание. Cрок выполнения зависят от:
            </p>
            <ul className={style.list}>
                {[
                    'Количества деталей в заказе',
                    'Габариты изделий',
                    'Необходимость предварительной обработки заготовок',
                    'Сложность самих деталей',
                ].map(li => <li>{li}</li>)}
            </ul>

            <Button
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--l"}
                // IconRight={'phone'}
                // IconColor={'var(--second)'}
                onClick={() => buttonHandler(form)}
            >Получить расчет стоимости
            </Button>

        </>
    );
};
