import React from 'react';
import style from './Layout.module.css'


export const Content = ({ children, backgroundColor }) => {
    return (

        <>
            {backgroundColor
                ?
                <div
                    className={style.container}
                    style={
                        {
                            backgroundColor: `${backgroundColor}`,
                        }
                    }
                >
                    <div className={style.content}>
                        {children}
                    </div>
                </div>
                :
                <div className={style.onlyContent}>
                    {children}
                </div>
            }

        </>
    );
};