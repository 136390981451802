import { db } from "./firebase-config"
import {
    collection,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    addDoc,
    doc,
    orderBy,
} from 'firebase/firestore'


class FirebaseData {

    addOrders = (newOrders) => {
        return addDoc(collection(db, 'orders'), newOrders)
    }
    getAllServices= () => {
        return getDocs(collection(db, 'orders'))
    }

    updateService = (id, serviceUpdate) => {
        const document = doc(db, 'orders', id)
        return updateDoc(document, serviceUpdate)
    }
    deleteService = (id) => {
        const document = doc(db, 'orders', id)
        return deleteDoc(document)
    }
    getService = (id) => {
        const document = doc(db, 'orders', id)
        return getDoc(document)
    }

}

export default new FirebaseData()