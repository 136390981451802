import React, { useRef } from 'react';
import style from './FormUnit.module.css'

export const UploadFile = ({
    label,
    uploadFile,
    selectedFile,
    setSelectedFile,

    errorMessage,
    ...inputProps }) => {


    const handleUploadFile = (e) => {
        setSelectedFile(e.target.files[0])
        uploadFile(e.target.files[0])
    }

    const fileRef = useRef(null)
    const handleRef = () => {
        fileRef.current.click()
    }

    return (

        <>
            <div className={style.file}>

                <input
                    {...inputProps}
                    type='file'
                    className={style.hidden}
                    onChange={handleUploadFile}
                    ref={fileRef}
                />

                <div className={style.buttonUploadFile} onClick={handleRef}>
                    <img src="/img/icon/AddFile.svg" />
                    <p>{label}</p>
                </div>

                {selectedFile && (

                    <>
                        <div className={style.containerUploadFile}>
                            <div className={style.filename}>
                                <img src="/img/icon/File.svg" />
                                <p >{selectedFile.name}</p>
                            </div>
                        </div>
                    </>

                )}
            </div>

        </>
    );
};