import React from 'react';
import { Button } from '../../Components/Button/Button';
import style from './Technology.module.css'

export const Frezer = ({ buttonHandler, form }) => {
    return (
        <>

            <h2 className={style.h2}>Фрезерные работы на станках с ЧПУ в Промстройсервис</h2>
            <p>
                Фрезерные работы с ЧПУ являются современным, экономичным и прогрессивным способом изготовления серийных/мелкосерийных деталей высокой точности. Фрезерные работы с ЧПУ снижают себестоимость, так как производство небольшого количества изделий всегда требует больших трудозатрат. <br /><br />Работы на ЧПУ фрезере могут применяться в случаях:
            </p>
            <ul className={style.list}>
                {[
                    'Поломка или трещина на детали',
                    'Нужное изделие снято с производства или поставка занимает долго времени',
                    'Изготовление новой детали из более прочного материала, чем оригинал, при модернизации техники',
                    'Получение высокого класса точности обработанной поверхности',
                    'Изготовление детали в единичном экземпляре, по индивидуальному проекту',
                ].map(li => <li>{li}</li>)}
            </ul>
            <p>
            Процесс обработки на фрезерных станках с ЧПУ позволяет обрабатывать заготовку в нескольких плоскостях (3-осевая, 4-осевая и 5-осевая). Возможность работы станка в разных направлениях, приводит к высокой производительности и точности изготовления изделия путем неизменного позиционирования детали.

            </p>


            <h3 className={style.h3}>Преимущества 5-осевой обработки на фрезерных ЧПУ, которая доступна на заказ в Промстройсервис:</h3>
            <ul className={style.list}>
                {[
                    'Сложная геометрия. Изготовление деталей со сложной конструкцией и геометрией',
                    'Высокая точность. Улучшенная точность благодаря обработке в одну установку: создание сложных форм без потери выравнивания, которое необходимо для поддержания высокого качества',
                    'Сокращение временного цикла. Режущий инструмент всегда близко к поверхностям и позволяет сократить время цикла',
                    'Высокая пропускная способность. Быстрое перемещение и позиционирование сокращают время оборота заготовки, повышают эффективность и точность производства',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Виды работ</h2>
            <p>
                Мы выполняем фрезерные работы на ЧПУ на заказ любого уровня сложности. Готовы производить высокоточные детали, в кратчайшие сроки, по индивидуальным проектам.
            </p>
            <ul className={style.list}>
                {[
                    'Фрезерование прямых поверхностей',
                    'Фрезерование пазов и канавок',
                    'Фрезерование фасонных поверхностей',
                    'Зубофрезерные работы с ТВЧ обработкой',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Мы предлагаем</h2>
            <ul className={style.list}>
                {[
                    'Возможность производства деталей от 1 штуки',
                    'Индивидуальная система скидок от объемов',
                    'Разработку и изготовление прототипов',
                    'Изготовление из разных материалов и сплавов',
                    'Высокий контроль качества и соответствия ТЗ',
                    'Точное соблюдение требований заказчика',
                    'Разработку и изготовление прототипов',
                    'Высокую точность обработки до 土0,005 мкм',
                    'Исключение человеческого фактора',
                    'Изготовление сложных токарно-фрезерных работ ЧПУ в разных плоскостях',
                    'Идентичность всех деталей',
                    'Возможность удаленного сопровождения заказа и соблюдение сроков доставки',
                ].map(li => <li>{li}</li>)}
            </ul>

            <h2 className={style.h2}>Технические возможности</h2>
            <table className={style.table}>
                <tbody>
                    <tr> <td>Максимальный диаметр обработки</td> <td>до 1600мм</td> </tr>
                    <tr> <td>Максимальная длина детали</td> <td>до 1000мм</td> </tr>
                    <tr> <td>Максимальный размер рабочего стола</td> <td>Ширина 2000 мм, длина 4000 мм, высота 1200 мм</td> </tr>

                </tbody>
            </table>

            <h2 className={style.h2}>Срок выполнения работ</h2>
            <p>
                Чтобы определить сроки изготовления изделия и цену, свяжитесь с нами или отправьте техническое задание. Cрок выполнения зависят от:
            </p>
            <ul className={style.list}>
                {[
                    'Количества деталей в заказе',
                    'Габариты изделий',
                    'Необходимость предварительной обработки заготовок',
                    'Сложность самих деталей',
                ].map(li => <li>{li}</li>)}
            </ul>

            <Button
                buttonStyle={"btn--yellow"}
                buttonSize={"btn--l"}
                // IconRight={'phone'}
                // IconColor={'var(--second)'}
                onClick={() => buttonHandler(form)}
            >Получить расчет стоимости
            </Button>

        </>
    );
};
