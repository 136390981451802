import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import React, { useEffect, useState } from 'react';
import { storage } from '../../Firebase/firebase-config';
import FirebaseData from '../../Firebase/firebase-data';
import { Button } from '../Button/Button';
import style from './Form.module.css'
import { Checkbox } from './FormUnit/Checkbox';
import { Input } from './FormUnit/Input';
import { TextArea } from './FormUnit/TextArea';
import { UploadFile } from './FormUnit/UploadFile';
import { DataForm } from './DataForm';
import { Modal } from '../Modal/Modal';



export const Form = ({ center, serviceName }) => {

    const { name, company, phone, email, comment, upload, personalData } = DataForm

    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [message, setMessage] = useState({ error: false, msg: '' })
    const [active, setActive] = useState()

    let dateNow = new Date()
    let date = String(dateNow.getDate()).padStart(2, '0') + '.' + String(dateNow.getMonth() + 1).padStart(2, '0') + '.' + dateNow.getFullYear();

    const [selectedFile, setSelectedFile] = useState(null)
    const [fileURL, setFileURL] = useState([])

    const [values, setValues] = useState({
        name: '',
        company: '',
        phone: '',
        email: '',
        comment: '',
        typeForm: 'order',
        date,
    })

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const uploadFile = (selectedFile) => {
        setLoading(true)
        const storageRef = ref(storage, `/FilesOrders/${Date.now()} + ${selectedFile.name}`)
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {

            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFileURL(downloadURL)
                    setLoading(false)
                });
            }
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const newOrder = {
            ...values, file: fileURL,
        }
        try {
            await FirebaseData.addOrders(newOrder)
            setMessage({ error: false, msg: 'Заявка отправлена, наш менеджер свяжется с вами в ближайшее время' })
        } catch (error) {
            console.log(error.message)
        }

        setSubmitted(true);
        setLoading(false)
        setTimeout(function () {
            window.location.reload();
        }, 3000);
    }


    return (
        <>
            {submitted
                ? <Modal
                    submitted={submitted}
                    setActive={setSubmitted}
                    loading={loading}>
                </Modal>
                : null
            }

            <form className={center ? style.formContainer : null} onSubmit={handleSubmit}>
                <div className={style.formBG}>
                    <div className={style.form}>
                        <p className={style.formTitle}>Отправьте нам заявку, мы произведем расчет стоимости и выбирем оптимальную технологию изготовления деталей</p>

                        <Input {...name}
                            value={values[name.name]}
                            onChange={onChange}
                        />
                        <Input {...company}
                            value={values[name.company]}
                            onChange={onChange}
                        />
                        <Input {...phone}
                            value={values[name.phone]}
                            onChange={onChange}
                        />
                        <Input {...email}
                            value={values[name.email]}
                            onChange={onChange}

                        />
                        <TextArea {...comment}
                            value={values[name.comment]}
                            onChange={onChange}

                        />
                    </div>
                    <div className={style.sumbit}>
                        <UploadFile
                            {...upload}
                            uploadFile={uploadFile}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile} />
                        <Checkbox
                            id={'order'}
                            {...personalData}
                        />
                        <div className={style.buttonContainer}>
                            <Button
                                type={'submit'}
                                disabled={loading}
                                buttonStyle={"btn--yellow"}
                                buttonSize={"btn--m"}
                                IconColor={'var(--text)'}
                            >Отправить
                            </Button>
                        </div>
                    </div>

                </div>
            </form>
        </>

    );
};
