import React from 'react';
import style from './Blocks.module.css'
import { Slider } from '../Slider/Slider';
import { Slide } from '../Slider/Slide';
import { Content } from '../Layout/Content';
import { Title } from '../Title/Title';
import { useGetDoc } from '../../Hooks/UseGetDoc';

const images = [
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork9.jpg?alt=media&token=fa3518b0-16f8-44bd-befa-3e283980189b',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork6.jpg?alt=media&token=24227a55-2370-4631-96fc-f633be5b48f8',

    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork8.jpg?alt=media&token=22ed7e78-a69f-417c-8f99-422dcd6cbc81',

    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork10.jpg?alt=media&token=c85cce2f-5225-4961-9cc2-f5fc92d69da0',

    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork1.jpg?alt=media&token=9ee1947e-0125-4c57-8d8e-f8b3dcf44231',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork11.jpg?alt=media&token=06318f2d-723f-46e1-85ca-a3b3922239bb',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork12.jpg?alt=media&token=ec7437f6-6866-4f49-8623-80b17285cfdd',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork13.jpg?alt=media&token=5681b366-9490-4a23-8626-768b87ec8f41',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork3.jpg?alt=media&token=1ab714d6-c3fd-425f-992f-002bf48545d8',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork4.jpg?alt=media&token=a7b7b42a-1fd1-48d1-9e5c-7d368b68fc76',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork5.jpg?alt=media&token=6a8c7705-5024-4e48-9654-74fce1a0edf5',
    'https://firebasestorage.googleapis.com/v0/b/promstroiservice-1d2a7.appspot.com/o/worksExample%2Fwork7.jpg?alt=media&token=300871d0-ae74-4673-b99d-2b042d62413e',
]

export const ExampleWork = () => {

    const { docs } = useGetDoc('workExample')
    console.log (docs)

    return (
        <Content backgroundColor={'#EFF0F2'}>
            <div className={style.content}>
                <Title
                    title={'Примеры работ'}
                />
                <div className={style.content}>
                    <Slider >
                        {images.map(image => <Slide image={image} />)}
                    </Slider>
                </div>
            </div>
        </Content>


    );
};
