import React, { useEffect } from 'react';
import { Footer } from '../Components/HeaderFooter/Footer';
import { Header } from '../Components/HeaderFooter/Header';
import style from './About.module.css'

export const About = () => {

    useEffect(() => {
        document.body.style.backgroundColor = '#fff';
    }, []);

    return (
        <>
            <div className={style.header}>
                <Header />
                <h1>О компании</h1>
            </div>

            <main>
                <div className={style.aboutText}>
                    <p>Основное направление Промстройсервис — серийная металлообработка и изготовление деталей на заказ по индивидуальным чертежам. Наш опыт, высокая квалификация и современное оборудование гарантируют качество и высокую точность изготовленного изделия.
                    </p>
                </div>

                <div className={style.city}>
                    <div className={style.map}>
                        <h2>География клиентов</h2>
                        <p>Широкая география заказчиков по всей России. Налаженная система ведения проектов на расстоянии и доставка готовых изделия в любой регион.</p>
                        <img src="/img/img/map_russia.png" />
                    </div>
                </div>

            </main>

            <Footer />
        </>
    );
};
